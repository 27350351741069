.App {
  background-image: url(./images/main-back-img2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}
.background-pseudo{
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
}
header img {
  width: 77px;
}
header p {
  font-size: 5em;
  color: #fff;
  font-weight: 600;
  line-height: 1.2em;
}
.header-title {
  color: #fff;
  font-size: 1.3em;
  text-align: center;
  font-weight: 400 !important;
}
.to-site-btn {
  text-transform: uppercase;
  background-color: #ffffff;
  color: rgb(5, 5, 5);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 32px;
  padding: 15px 25px;
  border: none;
  transition: 0.2s;
}
.to-site-btn:hover {
  background-color: #f5f5f5;
  font-size: 15px;
  border-radius: 10px;
  padding: 15px 25px;
}
.border-radius-logo{
  border-radius: 20%;
}
@media only screen and (max-width:1000px) and (min-width:768px) {
  header img {
    width: 70px;
  }
  header p {
    font-size: 4.3em;
  }
  .to-site-btn {
    font-size: 14px;
    border-radius: 30px;
    padding: 14px 24px;
  }
  .to-site-btn:hover {
    background-color: #036bd2;
    font-size: 14px;
    border-radius: 10px;
    padding: 14px 24px;
  }
}

@media only screen and (max-width:768px) {
  header img {
    width: 60px;
  }
  header p {
    font-size: 3.5em;
  }
  .to-site-btn {
    font-size: 13px;
    border-radius: 28px;
    padding: 13px 23px;
  }
  .to-site-btn:hover {
    background-color: #036bd2;
    font-size: 13px;
    border-radius: 10px;
    padding: 13px 23px;
  }
  .header-title {
    font-size: 1.2em;
  }
}